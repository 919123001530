import {fetchBaseQuery} from "@reduxjs/toolkit/query/react"

// Настойки для API Review
export const apiReviewQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_REVIEW,
  prepareHeaders: (headers, {getState}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const {auth} = getState()
    const token = auth.token

    headers.set("Accept-Language", "ru")
    headers.set("Authorization", `Bearer ${token}`)
    return headers
  }
})

// Настойки для API Delivery
export const apiDeliveryQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_DELIVERY,
  prepareHeaders: (headers, {getState}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const {auth} = getState()
    const token = auth.token

    headers.set("Accept-Language", "ru")
    headers.set("Authorization", `Bearer ${token}`)
    return headers
  }
})

export const getUrl = (url: string, params: any) => {
  const data = Object.entries(params).map(([name, value]) => ({name, value}))
  return data?.filter(item => !!item.value)?.length > 0
    ? joinToString(data, "&", url + "?", "", item => (item.value ? `${item.name}=${item.value}` : ""))
    : url
}

export function joinToString<T>(array: T[], separator = ",", prefix = "", suffix = "", transform: (item: T) => string) {
  let res = prefix
  for (let i = 0; i < array.length; i++) {
    if (i !== 0 && transform(array[i]) !== "") {
      res += separator
    }
    res += transform(array[i])
  }
  res += suffix
  return res
}
