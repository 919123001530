import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseFeedbackMore} from "types/ResponseFeedbackMore"
import {Audio} from "../../types/FeedbackMore"
import { ResponseFeedbacks } from "types/ResponseFeedbacks"
import { ArgsProps } from "features/feedback-table/fetchFeedbacks"
import { ResponseCustomerReview } from "types/ResponseCustomerReview"

export const feedbackApi = createApi({
    reducerPath: "feedbackApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["feedbackMore"],
    endpoints: builder => ({
        // Вывод отзыва по id
        getFeedbackById: builder.query<ResponseFeedbackMore, Partial<string | undefined>>({
            query: id => `feedbacks/${id}`,
            providesTags: ["feedbackMore"]
        }),
        getAudioById: builder.mutation<Audio, string>({
            query: (id) => `feedbacks/call/${id}`,
            invalidatesTags: ["feedbackMore"]
        }),
        getCustomerReview: builder.query<{
            success: boolean,
            data: ResponseCustomerReview[]
        }, string | undefined>({
            query: (customer_phone: string) => `feedbacks/customers/${customer_phone}`,
            providesTags: ["feedbackMore"]
        }),
        getPhoneUrl: builder.query<null, {phone_number: string, feedback_id: string}>({
            query: data => ({
                url: `/feedbacks/call`,
                method: "POST",
                body: {
                    feedback_id: data.feedback_id,
                    phone_number: data.phone_number
                }
            })
        }),
        updateAssignedEmployee: builder.mutation<{ success: boolean, message: string }, Partial<{id: number, assigned_employee_id: number | null}>>({
            query: ({id, assigned_employee_id}) => ({
                url: `/feedbacks/${id}`,
                method: "PATCH",
                body: {
                    assigned_employee_id: assigned_employee_id
                }
            }),
            invalidatesTags: ["feedbackMore"]
        }),
        updateAttachments: builder.mutation<{ success: boolean, message: string }, Partial<{id: number, attachmentIds: string[]}>>({
            query: ({id, attachmentIds}) => ({
                url: `/feedbacks/${id}`,
                method: "PATCH",
                body: {
                    attachment_ids: attachmentIds
                }
            })
        }),
        getExcelOfFeedbacks: builder.mutation<ResponseFeedbacks, ArgsProps>({
            query: (body) => ({
                url: "/feedbacks/download",
                method: "POST",
                body: body
            })
        }),
        uploadAttachments: builder.mutation<{ success: boolean, data: string[] }, Partial<{file: File}>>({
            query: ({file}) => {
                const formData = new FormData()
                formData.append("files", file as Blob)
                return {
                    url: "/attachments/upload",
                    method: "POST",
                    body: formData
            }
        }}),
        checkFeedback: builder.mutation<{
            success: boolean,
            data: {
                number: number,
                status: string,
                created_date: string,
                message: string,
                color: string
            }[]
        }, {
            customer_phone?: string,
            order_id?: string
        }>({
            query: (data) => ({
                url: "/feedbacks/check",
                method: "POST",
                body: data
            })
        }),
        checkFeedbackCoupon: builder.mutation<{
            success: boolean,
            data: {
                feedback_id: string,
                name: string,
                sent_date: string,
            }[]
        }, {
            orderId?: number | null
        }>({
            query: ({orderId}) => ({
                url: `/feedbacks/coupon/${orderId}`,
                method: "GET",
            })
        }),
    })
})

export const {useUpdateAttachmentsMutation,useGetFeedbackByIdQuery, useLazyGetCustomerReviewQuery, useGetAudioByIdMutation, useLazyGetPhoneUrlQuery, useUpdateAssignedEmployeeMutation, useGetExcelOfFeedbacksMutation, useUploadAttachmentsMutation, useCheckFeedbackMutation, useCheckFeedbackCouponMutation} = feedbackApi